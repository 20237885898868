import React from 'react';
import { Image } from 'antd';
import logo from '../Assets/logo.png';
import bgMedia from '../Assets/BgPicture.png'

const CustomLoader = () => {
    return (
        <div style={{ height: '100vh', display: 'grid', placeItems: 'center', background:`url(${bgMedia})` , backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundSize:'cover'}}>
            <div className='text-center'>
                {/* <Image preview={false} src={logo} height={140} width={140} /> */}
                {/* <h2 className='mt-4 text-white' style={{ textAlign: 'center' }}>Please wait...!</h2> */}
            </div>
        </div>
    )
}

export default CustomLoader